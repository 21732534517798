// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
const firebaseConfig = {
  apiKey: "AIzaSyDIuX4kX-XmjNmjlpfmXRA6UMNxGhIt8cA",
  authDomain: "indunee1.firebaseapp.com",
  databaseURL: "https://indunee1-default-rtdb.firebaseio.com",
  projectId: "indunee1",
  storageBucket: "indunee1.appspot.com",
  messagingSenderId: "611704818657",
  appId: "1:611704818657:web:b32fb5c8dd69cbaa9aa0a0",
  measurementId: "G-9RPL116W7P"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { app };